import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { IndexPageGatsbyCDAContainer } from '@containers/Index'
import { graphql } from 'gatsby'

const Person = ({ pageContext, data }) => {
  const seo = { locale: pageContext?.locale, metaTitle: pageContext?.name }

  const items = data.items.nodes

  return (
    <Layout {...pageContext} seo={seo}>
      <IndexPageGatsbyCDAContainer
        type="person"
        {...pageContext}
        items={items}
      />
    </Layout>
  )
}

Person.propTypes = {}

export default Person

export const query = graphql`
  query Person(
    $skip: Int!
    $limit: Int!
    $locale: String!
    $relatedArticles: [String!]
  ) {
    items: allContentfulArticle(
      skip: $skip
      limit: $limit
      filter: {
        contentful_id: { in: $relatedArticles }
        node_locale: { eq: $locale }
      }
      sort: {
        fields: [publicationDate, author___id, createdAt, title]
        order: [DESC, ASC]
      }
    ) {
      nodes {
        ...relatedArticleFields
      }
    }
  }
`
